import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { socket } from "../../socket";
import "./Home.scss";

const Home = () => {
  const navigate = useNavigate();

  const [roomCode, setRoomCode] = useState("");

  const handleCreate = () => {
    socket.emit("create-room");
  };

  const handleJoin = () => {
    if (roomCode.length === 3) socket.emit("join-room", roomCode);
  };

  useEffect(() => {
    socket.on("admit-player", () => {
      navigate("/create-player");
    });

    // cleanup listeners on dismount
    return () => {
      socket.off("admit-player");
    };
  }, [null]);

  return (
    <main className="homeContainer animated fadeIn">
      <h1 className="animated bounce">UNO Game</h1>
      <button
        className="createButton animated lightSpeedIn"
        onClick={handleCreate}
      >
        Create Room
      </button>
      <div className="divider animated fadeIn">
        <span>or</span>
      </div>
      <div className="roomInputContainer animated fadeInUp">
        <input
          className="roomInput"
          type="text"
          placeholder="Enter Room Code"
          maxLength={3}
          value={roomCode}
          onChange={(event) => setRoomCode(event.target.value.toUpperCase())}
        />
      </div>
      <button className="joinButton animated lightSpeedIn" onClick={handleJoin}>
        Join Game
      </button>
    </main>
  );
};

export default Home;
